<template>
  <div class="sztt">
    <div class="jcxz">
    <el-dialog
      :title="previewData.title"
      :visible.sync="dialogVisible"
      width="60%">
      <div>
        <template v-if="previewData.thumbnailInfo">
          <div v-if="previewData.thumbnailInfo.url" class="preview_data_img">
            <img :src="previewData.thumbnailInfo.url" alt="">
          </div>
        </template>
        <div class="preview_data_html" v-html="previewData.content"></div>
      </div>
      <span slot="footer" class="dialog-footer">
      </span>
    </el-dialog>
    <div class="page-herader">
      <span>{{content.name}}</span>
    </div>
    <div class="lmy-center" v-if="content.children.length > 0">
      <div class="jcxz_item" v-for="(item, index) in content.children" :key="index">
        <div class="teachers_lanmu">{{ item.name }}</div>
        <div class="teachers_name">
          <div
            class="teachers_lb"
            v-for="(citem, cindex) in item.contentData"
            :key="cindex"
            :title="citem.title"
            @click="preview(citem)"
          >
            {{ citem.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
    data() {
    return {
      previewData: '',
      dialogVisible: false,
      content: '',
      erOriginLinkShow: '',
      treelist:[]
    };
  },
  watch: {
    content(val) {
      if(val) {
        this.getHomeRotographListFun()
      }
    }
  },
  created() {
    this.getTreeList();
  },
  methods: {
    async getTreeList() {
      const res = await this.API.basic.getColumn();
      if (res.data && res.data.length > 0) {
        let findColumn = res.data.findIndex(i => { return i.uuid == this.$columnIdFile.szdw})
        findColumn != '-1' ? this.treelist = res.data[findColumn].children : this.treelist = []
        this.content = this.treelist[0]
      }
    },
    preview(val) {
      this.dialogVisible = true
      this.previewData = val
    },
    getHomeRotographListFun() {
      if(this.content.children.length) {
        this.content.children.forEach( async i => {
          const params = {
            pageNumber: 0,
            pageSize: 100,
            columnIds: i.uuid
          }
          const res = await this.API.basic.getHomeRotographList(params);
          i.contentData = res.data.content
          this.$forceUpdate()
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sztt{
    padding: 0 250px 50px;
    .jcxz{
        background: white;
    }
}
.preview_data_img{
  display: flex;
  justify-content: center;
  img{
    height: 300px;
    max-width: 100% !important;
  }
}
.preview_data_html{
  ::v-deep img{
    max-width: 100% !important;
  }
}
.teachers_lanmu {
  padding: 10px 0 10px 25px;
  border-bottom: solid 1px #d6d6d6;
  line-height: 25px;
  font-size: 16px;
  font-family: "微软雅黑";
  background: url("../../assets/img/jtIcon.png") 10px no-repeat;
  background-size: 10px 10px;
  color: #a73728;
}
.teachers_name {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;
}
.teachers_lb {
  font-size: 16px;
  cursor: pointer;
  width: 14%;
  height: 54px;
  text-align: center;
  padding: 10px 0;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
